/**
 * Debounce function execution by a given amount of time
 * @param {Function} callback Callback to fire
 * @param {Number} wait Amount of time to pass before callback is fired
 * @returns {Function}
 */
export function debounce(callback, wait = 200) {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
}

/**
 * Throttle function execution by a given amount of time
 * @param {Function} callback Callback to call when timeout has elapsed
 * @param {Number} wait Time (in milliseconds) to wait before callback is invoked
 * @param {Boolean} immediate Invoke callback immediately on first call
 * @returns {Function}
 */
export function throttle(callback, wait = 200, immediate = false) {
  let timeout = null;
  let initialCall = true;
  
  return function() {
    const callNow = immediate && initialCall;
    const next = () => {
      callback.apply(this, arguments);
      timeout = null;
    }
    
    if (callNow) { 
      initialCall = false;
      next();
    }

    if (!timeout) timeout = setTimeout(next, wait);
  }
}

/**
 * Clamp a value between a minimum and maximum value
 * @param {Number} min Minimum value
 * @param {Number} value Current value
 * @param {Number} max Maximum value
 * @returns {Number}
 */
export function clamp(min, value, max) {
  return Math.min(Math.max(min, value), max);
}

/**
 * Convert an array to an object using a given key
 * @param {Array<{}>} array Array to convert into an object
 * @param {String} key Key to use for object keys
 * @example
 *    const value = [{ id: 'name', 'value': 2 }, { id: 'something', 'value': 1 }];
 *    const result = convertArrayToObject(value, 'id');
 *    return result.something; // { 'value': 1 }
 * @returns {{}}
 */
export function convertArrayToObject(array, key) {
  const result = {};
  array.forEach((item) => {
    if (typeof result[item[key]] === 'undefined') {
      result[item[key]] = [];
    }

    result[item[key]].push(item);
  });

  return result;
}

/**
 * Deeply merge multiple objects into a single object
 * @param {Object} target 
 * @param {...any} sources 
 * @returns {Object}
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}