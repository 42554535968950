/**
 * Cookie Preferences handler for Google Tagmanager v0.5
 * (c) 2023 Chatter Communications
 * Author: Rick Harrrison rick@welovechatter.com
 *         Aaron Kirkham aaron@welovechatter.com
 * 
 * This is a relatively generic handler for asking for people's permissions to use cookies on a site. It's design
 * agnostic - if just expects the following items to work:
 * 
 * 1. A master container for the cookie popover with the ID #cookie-notice-container, with an inline style of display: none;
 * 2. Within the master container 2 sibling sub containers with the IDs #cookie-notice && #cookie-settings
 */

/*
  Types of Cookie
  https://gdpr.eu/cookies/

  Strictly necessary cookies — These cookies are essential for you to browse the website and use its features, such as accessing secure areas of the site. Cookies that allow web shops to hold your items in your cart while you are shopping online are an example of strictly necessary cookies. These cookies will generally be first-party session cookies. While it is not required to obtain consent for these cookies, what they do and why they are necessary should be explained to the user.

  Preferences cookies — Also known as “functionality cookies,” these cookies allow a website to remember choices you have made in the past, like what language you prefer, what region you would like weather reports for, or what your user name and password are so you can automatically log in.

  Statistics cookies — Also known as “performance cookies,” these cookies collect information about how you use a website, like which pages you visited and which links you clicked on. None of this information can be used to identify you. It is all aggregated and, therefore, anonymized. Their sole purpose is to improve website functions. This includes cookies from third-party analytics services as long as the cookies are for the exclusive use of the owner of the website visited.

  Marketing cookies — These cookies track your online activity to help advertisers deliver more relevant advertising or to limit how many times you see an ad. These cookies can share that information with other organizations or advertisers. These are persistent cookies and almost always of third-party provenance.
*/

import { mergeDeep } from './utils';

const defaultOptions = {
  /**
   * Selector for the root notice container element
   */
  noticeContainerSelector: '#cookie-notice-container',

  /**
   * Set default cookie enabled values
   */
  enabledCookies: {
    preferences: true,
    statistics: true,
    marketing: true,
  },
};

export const CookiePreferences = {
  /**
   * @type {defaultOptions}
   */
  options: {},

  /**
   * @type {HTMLElement}
   */
  noticeContainerEl: null,

  settingsAccepted: false,
  minimisedPopoverHeight: 0,
  totalPopoverHeight: 0,
  listenerAdded: false,

  /**
   * Initialise the Cookie notice bar (requires options.noticeContainerSelector element to be in the DOM)
   * This will load cookie preferences, bind events and deal with GTM dataLayer
   * @param {defaultOptions} options Configuratable options
   */
  init(options = {}) {
    this.options = mergeDeep(defaultOptions, options);

    this.noticeContainerEl = document.querySelector(this.options.noticeContainerSelector);
    if (!this.noticeContainerEl) {
      console.error(`Chatter Cookies : Can't initialise cookie consent actions. (Missing ${this.options.noticeContainerSelector} element)`);
      return;
    }

    // load cookie settings
    this.loadCookieSettings();

    // add/update data-layer variables.
    this.updateDataLayer();

    // show the cookie popover if the cookie notice hasn't been dismissed
    if (!this.settingsAccepted) {
      setTimeout(() => this.showCookiePopover(), 0);
    }

    // bind all .js-cookie-change-settings links in the page to trigger the cookie popover
    const actionChangeSettings = document.querySelectorAll('.js-cookie-change-settings');
    actionChangeSettings.forEach(link => link.addEventListener('click', () => {
      this.toggleCookieSettings(true);
      this.showCookiePopover();
    }));

    // 
    const cookieSettingsLink = document.querySelector('.js-cookie-view-settings');
    if (cookieSettingsLink) {
      // because we use <buttons> with <spans>
      function targetContainsClassName(event, className) {
        if (event.target.classList.contains(className)) return true;
        else if (event.target.parentNode.classList.contains(className)) return true;
        return false;
      }

      // i'm adding the click listener to body and checking the target - just attaching the event handlerr
      // to the actual like fails - SOMETHING appears to be preventDefaulting and stopping it running, adding
      // the listener at BODY level sees to work tho.
      document.body.addEventListener('click', (event) => {
        if (targetContainsClassName(event, 'js-cookie-view-settings')) {
          event.preventDefault();
          this.toggleCookieSettings();
        }

        // save settings button
        if (targetContainsClassName(event, 'js-cookie-save-settings')) {
          event.preventDefault();
          this.acceptSettings();
        }

        // accept all button
        if (targetContainsClassName(event, 'js-cookie-accept-all')) {
          event.preventDefault();
          this.toggleCookie('preferences', true);
          this.toggleCookie('statistics', true);
          this.toggleCookie('marketing', true);
          this.acceptSettings();
        }

        // accept all button
        if (targetContainsClassName(event, 'js-cookie-decline-all')) {
          event.preventDefault();
          this.toggleCookie('preferences', false);
          this.toggleCookie('statistics', false);
          this.toggleCookie('marketing', false);
          this.acceptSettings();
        }
      });
    }
  },

  /**
   * Load cookie preferences from local storage
   */
  loadCookieSettings() {
    const settings = localStorage.getItem('chatter_cookie_settings');
    if (settings) {
      try {
        this.options.enabledCookies = JSON.parse(settings);
      } catch (e) {}
    }

    this.settingsAccepted = localStorage.getItem('chatter_cookie_notice_accepted');
    if (this.settingsAccepted == null) {
      this.settingsAccepted = false;
    }

    this.updateCheckboxValues();
  },

  /**
   * Save cookie preferences to local storage
   */
  saveCookieSettings() {
    localStorage.setItem('chatter_cookie_settings', JSON.stringify(this.options.enabledCookies));
  },

  /**
   * Update checkbox checked state based on the enabled state
   */
  updateCheckboxValues() {
    const checkboxesContainer = this.noticeContainerEl.querySelector('#cookie-settings__checkboxes');

    const checkboxes = checkboxesContainer.querySelectorAll('input[type=checkbox]');
    checkboxes.forEach((checkbox) => {
      const { value } = checkbox;

      // get the default value from the gdpr cookie settings object
      const checked = this.options.enabledCookies[value];

      // update checkbox state
      checkbox.checked = checked;

      // toggle enabled/disabled message state
      const message = checkboxesContainer.querySelector(`#cookies-${value}-state`);
      if (message) {
        message.innerHTML = message.dataset[checked ? 'enabledMsg' : 'disabledMsg'];
      }
    });
  },

  /**
   * Toggle cookie settings view
   * @param {Boolean} force Force override (optional)
   */
  toggleCookieSettings(force = undefined) {
    const acceptAllButton = this.noticeContainerEl.querySelector('.js-cookie-accept-all');
    const declineAllButton = this.noticeContainerEl.querySelector('.js-cookie-decline-all');
    const saveSettingsButton = this.noticeContainerEl.querySelector('.js-cookie-save-settings');
    const copyToHideOnEdit = this.noticeContainerEl.querySelector('.js-cookie-hide-on-edit');

    if (copyToHideOnEdit) {
      copyToHideOnEdit.style.display = 'none';
    }

    const showingSettings = this.noticeContainerEl.classList.toggle('show-settings', force);

    // show settings
    if (showingSettings) {
      this.noticeContainerEl.style.transform = `translateY(-${this.noticeContainerEl.offsetHeight}px)`;
      acceptAllButton.style.display = 'none';
      if (declineAllButton) declineAllButton.style.display = 'none';
      saveSettingsButton.style.display = null;
      this.listenForCookieChanges();
    }
    // hide settings
    else {
      this.noticeContainerEl.style.transform = null;
      acceptAllButton.style.display = null;
      if (declineAllButton) declineAllButton.style.display = null;
      saveSettingsButton.style.display = 'none';
    }
  },

  /**
   * Toggle cookie on/off by a given cookie name
   * @param {String} cookieName Cookie name to set (e.g. performance, statistics, marketing)
   * @param {Boolean} toggle Toggle preferences cookies on/off
   */
  toggleCookie(cookieName, toggle) {
    this.options.enabledCookies[cookieName] = toggle;
    this.updateDataLayer();

    const allowOrDeny = toggle ? 'allow' : 'deny';
    window.dataLayer.push({'event': `cookie-acceptance-${allowOrDeny}-${cookieName}`});
    window.dataLayer.push({'event': 'cookie-acceptance-changed'});

    this.saveCookieSettings();

    // toggle enabled/disabled message state
    const message = document.querySelector(`#cookies-${cookieName}-state`);
    if (message) {
      message.innerHTML = message.dataset[toggle ? 'enabledMsg' : 'disabledMsg'];
    }

    this.updateCheckboxValues();
  },

  /**
   * Flag the cookie acceptance message as accepted
   */
  acceptSettings() {
    this.settingsAccepted = true;
    localStorage.setItem('chatter_cookie_notice_accepted', true);
    this.hideCookiePopover();
  },

  /**
   * Add event listener to the cookie checkboxes and toggle the cookie preferences accordingly
   */
  listenForCookieChanges() {
    if (this.listenerAdded) return;

    this.listenerAdded = true;

    const checkboxesContainer = this.noticeContainerEl.querySelector('#cookie-settings__checkboxes');
    checkboxesContainer.addEventListener('change', (event) => {
      const { value, checked } = event.target;

      // update the cookie settings
      this.toggleCookie(value, checked);
    });
  },


  /**
   * Resize the cookie popover container
   */
  sizeCookiePopover() {
    this.minimisedPopoverHeight = this.noticeContainerEl.offsetHeight;
    this.totalPopoverHeight = document.querySelector('#cookie-notice').offsetHeight;

    let height = this.totalPopoverHeight;
    if (this.noticeContainerEl.classList.contains('show-settings')) {
      height = this.minimisedPopoverHeight;
    }

    this.noticeContainerEl.style.transform = `translateY(-${height}px)`;
  },

  /**
   * Show the cookie popover
   */
  showCookiePopover() {
    this.noticeContainerEl.style.display = 'block';
    this.sizeCookiePopover();
    this._sizeCookiePopover = this.sizeCookiePopover.bind(this);
    // TODO : debounce the resize
    window.addEventListener('resize', this._sizeCookiePopover);
  },

  /**
   * Hide the cookie popover
   * This will reset the sizes of the cookie popover before hiding it.
   */
  hideCookiePopover() {
    this.noticeContainerEl.classList.remove('show-settings');
    this.sizeCookiePopover();
    window.removeEventListener('resize', this._sizeCookiePopover);
    this._sizeCookiePopover = null;
    this.noticeContainerEl.style.display = 'none';
  },

  /**
   * Update GTM dataLayer
   */
  updateDataLayer() {
    if (typeof(window.dataLayer) === 'undefined') {
      window.dataLayer = [{'TagmanCookiePrefs_allowed': ''}];
    }

    const allowedCookies = ['strictly_necessary'];
    if (this.options.enabledCookies.preferences) allowedCookies.push('preferences');
    if (this.options.enabledCookies.statistics) allowedCookies.push('statistic');
    if (this.options.enabledCookies.marketing) allowedCookies.push('marketing');

    window.dataLayer.push({'TagmanCookiePrefs_allowed': allowedCookies.join()});

    if (typeof(window.TagmanCookiePrefs_loaded) === 'undefined') {
      window.dataLayer.push({'event': 'cookie-acceptance-initialized'});
      window.TagmanCookiePrefs_loaded = true;
    }
  }
};

/**
 * Set a cookie with a value and an expire date
 * @param {String} cookieName Name of the cookie
 * @param {String} value Value of the cookie 
 * @param {Number} expiresInDays How many days until the cookie expires
 */
export function setCookie(cookieName, value, expiresInDays) {
  const date = new Date();
  date.setTime(date.getTime() + (expiresInDays * 24 * 60 * 60 * 1000));
  document.cookie = `${cookieName}=${value};expires=${date.toUTCString()};path=/`;
}

/**
 * Get a cookie value from the document cookies
 * @param {String} cookieName Name of the cookie 
 * @returns {String}
 */
export function getCookie(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  for (const part of decodedCookie.split(';')) {
    let tmp = part;
    while (tmp.charAt(0) === ' ') {
      tmp = tmp.substring(1);
    }

    if (tmp.indexOf(name) === 0) {
      return tmp.substring(name.length, tmp.length);
    }
  }

  return '';
}