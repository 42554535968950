import Croppie from 'croppie';

export class LogoCrop {
  constructor({ width, height }) {
    this.width = width;
    this.height = height;
    this.croppie = null;
    this.blob = null;
    this.url = null;

    // prefetch elements
    this.previewContainerEl = document.querySelector('.logo-preview');
    this.previewImgEl = document.getElementById('logo-preview-img');
    this.inputEl = document.getElementById('logo');
    this.croppieEl = document.getElementById('logo-preview-croppie');
    this.cropButtonEl = document.querySelector('.logo-preview .edit-button');

    this.previewImgEl.style = `width:${width}px;height:${height}px`;

    // bind crop button event listener
    this.cropButtonEl.addEventListener('click', this.toggleCrop.bind(this));
  }

  // destroy croppie instance and reset things back to normal
  destroyCroppie() {
    if (this.croppie) {
      this.cropButtonEl.innerText = 'Crop';
      this.previewContainerEl.classList.remove('editing');
      this.croppie.destroy();
      this.croppie = null;
    }
  }

  // crop button event handler, toggle croppie
  toggleCrop(event) {
    event.preventDefault();

    // button clicked and croppie is initialized, get the crop result and
    // destroy the croppie instance
    if (this.croppie) {
      this.cropButtonEl.innerText = 'Crop';

      this.croppie.result({ type: 'blob', size: 'original' })
        .then((res) => {
          this.load(res);
          this.previewImgEl.src = this.url;
          this.destroyCroppie();
        });
    } else {
      this.cropButtonEl.innerText = 'Save';
      this.previewContainerEl.classList.add('editing');

      this.croppie = new Croppie(this.croppieEl, {
        viewport: {
          width: this.width,
          height: this.height,
          type: 'square',
        },
        enforceBoundary: false,
      });

      // bind croppie source from the preview image
      this.croppie.bind({
        url: this.previewImgEl.src,
      });
    }
  }

  // init logo preview, create object url for blob
  load(file) {
    this.freeObjectURL();
    this.blob = file;
    this.url = URL.createObjectURL(file);
    this.previewImgEl.src = this.url;
    this.previewContainerEl.classList.remove('empty');
  }

  // free blob and reset to normal
  reset() {
    this.destroyCroppie();
    this.freeObjectURL();
    this.blob = null;
    this.previewContainerEl.classList.add('empty');
  }

  // reset input file field
  resetInput() {
    this.inputEl.value = '';
  }

  // free allocated object URL
  freeObjectURL() {
    if (this.url) {
      URL.revokeObjectURL(this.url);
      this.url = null;
    }
  }

  getBlob() {
    return this.blob;
  }
};